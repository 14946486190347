import { $authHost } from "..";

export default class myRequestsEmployeeHttp {

  static employeeRequestsLinks() {
    return $authHost.get(`/employee/get_forms`);
  };
  static employeeRequestFormFromClientLinks(id) {
    return $authHost.get(`/employee/get_forms/${id}`);
  };
}
