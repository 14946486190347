export const columnsMyRequestsEmployee = (onClickView) => [
  {
    accessorKey: 'Request',
    header: 'Request', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['name']}</p>,
  },
  {
    accessorKey: 'Client',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school ?? ''}</p>,
  },
//   {
//     accessorKey: 'deadline',
//     header: 'Service Type', //uses the default width from defaultColumn prop
//     // hishem avelacnem
//     // size: 40,
//     Cell: ({ row }) => (
//       <p className="whitespace-nowrap">
//         {row?.original?.['cases']?.map(
//           ({ service_type_name }, index, arr) => `${service_type_name} ${index != arr?.length - 1 ? ', ' : " "}`,
//         )}
//       </p>
//     ),
//   },
  {
    accessorKey: 'deadline',
    header: 'Deadline', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['deadline']}</p>,
  },
  {
    accessorKey: 'action',
    header: 'Action',
    size: 120, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
        {onClickView && (
          <button
            onClick={() => onClickView(row?.original?.id)}
            className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            type="button">
            View
          </button>
        )}
      </div>
    ),
  },
];
