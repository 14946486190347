export const columnsDashboardEmployee = [
  {
    accessorKey: 'Client',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.Client ?? ''}</p>,
  },
  {
    accessorKey: 'Address',
    header: 'Address', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['Address']}</p>,
  },
  {
    accessorKey: 'My Position',
    header: 'My Position', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['My Position']}</p>,
  },
];
export const columnsDashboardEmployeeCase = [
  {
    accessorKey: 'Client',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school_name ?? ''}</p>,
  },
  {
    accessorKey: 'Service Type',
    header: 'Service Type', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => (
      <p className="whitespace-nowrap">
        {row?.original?.['cases']?.map(
          ({ service_type_name }, index, arr) => `${service_type_name} ${index != arr?.length - 1 ? ', ' : " "}`,
        )}
      </p>
    ),
  },
  {
    accessorKey: 'Case',
    header: 'Case', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['name']}</p>,
  },
];
export const columnsDashboardEmployeeRequest = 
[
  {
    accessorKey: 'Request',
    header: 'Request', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['name']}</p>,
  },
  {
    accessorKey: 'Client',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school ?? ''}</p>,
  },
//   {
//     accessorKey: 'deadline',
//     header: 'Service Type', //uses the default width from defaultColumn prop
//     // hishem avelacnem
//     // size: 40,
//     Cell: ({ row }) => (
//       <p className="whitespace-nowrap">
//         {row?.original?.['cases']?.map(
//           ({ service_type_name }, index, arr) => `${service_type_name} ${index != arr?.length - 1 ? ', ' : " "}`,
//         )}
//       </p>
//     ),
//   },
  {
    accessorKey: 'deadline',
    header: 'Deadline', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['deadline']}</p>,
  },
];
