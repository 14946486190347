import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import InputLabel from '../../components/InputLabel/InputLabel';
import CheckboxesFields from './components/CheckboxesFields';
import EditFormAdminDefaultValue from './EditFormAdminDefaultValue';

import requestHttp from '../../http/requestHttp/requestHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Service from '../../http/seviceHttp/seviceHttp';
import CurrencyInputNew from '../../components/CurrencyInputNew/CurrencyInputNew';
import Select from '../../components/Select/Select';
// import { yupResolver } from '@hookform/resolvers/yup';
// import RequestErrorEditFormAdmin from './RequestErrorEditFormAdmin';

export default function EditFormAdmin() {
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServicesData] = useState([]);
  const [form, setForm] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const afterSubmit = () => {
    notifySuccess('Request successfully changed');
    setTimeout(() => {
      navigate('/Admin/Requests');
    }, 2000);
  };

  const OnClickCancle = () => {
    navigate('/Admin/Requests');
  };
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => EditFormAdminDefaultValue(form), [form]),
    // resolver: yupResolver(RequestErrorEditFormAdmin()),
  });

  const { fields: Fields } = useFieldArray({
    control,
    name: 'formFields',
  });

  const onSubmit = data => {
    const sendData = {
      service_id: data?.service_id,
      name: data?.name,
      acceptable_hours: data?.acceptable_hours?.replace(".", ":"),
      hourly_rate: data?.hourly_rate,
      formFields: data?.formFields.map(elem => {
        return {
          id: elem.id,
          value: elem?.type === 'checkbox' ? null : elem?.value,
          options: elem?.type === 'checkbox' ? elem?.options : null,
        };
      }),
    };

    setIsLoading(true);
    requestHttp
      .editSubmittedFormAdmin(params.id, sendData)
      .then(() => afterSubmit())
      .catch(err => {
        err && setIsLoading(false);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
      });
  };

  useEffect(() => {
    reset(EditFormAdminDefaultValue(form));
  }, [form]);

  useEffect(() => {
    requestHttp.getSubmittedFormClient(params?.id).then(e => {
      setForm(e?.data?.data);
    });
    Service.serviceLinks()
      .then(res =>
        setServicesData(
          res.data?.data?.map(({ id, name }) => ({
            id,
            name,
          })),
        ),
      )
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setValue('service_id', form?.service_id);
  }, [services]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Edit Form for ${form?.title}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="py-4 w-full">
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
              {Fields?.map(({ label, type, id }, index) =>
                type === 'input' ? (
                  <div className="w-full" key={id}>
                    <InputLabel
                      labelTitle={label}
                      register={register}
                      name={`formFields.${index}.value`}
                      classNameLabel="text-[#60657B] opacity-50 text-xs"
                      classNameInput="flex flex-col flex-1"
                      type={'text'}
                      placeholder={label}
                      className="w-full"
                      required={{ required: 'This field is required' }}
                    />
                    {errors && (
                      <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                    )}
                  </div>
                ) : (
                  type === 'date' && (
                    <div className="" key={id + label}>
                      <InputLabel
                        classNameInput="flex flex-col "
                        classNameLabel={'font-[system-ui] text-[#60657B] text-xs font-normal opacity-50'}
                        className="w-full border p-[7px]"
                        type="date"
                        labelTitle={label}
                        register={register}
                        name={`formFields.${index}.value`}
                        required={{ required: 'This field is required' }}
                      />
                      {errors && (
                        <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                      )}
                    </div>
                  )
                ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ name, label, type, id }, index) =>
                  type === 'checkbox' && (
                    <div className="space-y-5 h-full" key={id + name + label}>
                      <p className="text-lg font-semibold">{label}</p>
                      <CheckboxesFields control={control} register={register} index={index} />
                    </div>
                  ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              <div>
                <InputLabel
                  labelTitle={'Request Name'}
                  className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={'Request Name'}
                  name={`name`}
                  register={register}
                  classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  required={{ required: 'This field is required' }}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.name?.message}</p>}
              </div>
              {/* <div>
                <InputLabel
                  labelTitle={'Acceptable Hours'}
                  className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={'Acceptable Hours'}
                  name={`acceptable_hours`}
                  register={register}
                  classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  required={{ required: 'This field is required' }}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.acceptable_hours?.message}</p>}
              </div> */}
              {form?.acceptable_hours ? (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-1 text-start h-[43px]'}
                  name={`acceptable_hours`}
                  setValue={setValue}
                  placeholder="Acceptable Hours"
                  defaultValue={+form?.acceptable_hours.replace(":", ".")}
                  labelTitle={'Acceptable Hours'}
                  prefix=''
                  decimalSeparator=":"
                />
              ) : (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-1 text-start h-[43px]'}
                  name={`acceptable_hours`}
                  setValue={setValue}
                  placeholder="Acceptable Hours"
                  labelTitle={'Acceptable Hours'}
                  prefix=''
                  decimalSeparator=":"
                />
              )}
              {form?.hourly_rate ? (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-1 text-start h-[43px]'}
                  name={`hourly_rate`}
                  setValue={setValue}
                  placeholder="Hourly Rate $ 0.00"
                  defaultValue={form?.hourly_rate}
                  labelTitle={'Hourly Rate'}
                />
              ) : (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-1 text-start h-[43px]'}
                  name={`hourly_rate`}
                  setValue={setValue}
                  placeholder="Hourly Rate $ 0.00"
                  labelTitle={'Hourly Rate'}
                />
              )}
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Service'}
                  selectData={services ?? []}
                  selectClass="flex-col gap-2"
                  name={'service_id'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  required={{ required: 'This field is required' }}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.service_id?.message}</p>}
              </div>
            </div>
          </div>
          <div>
            <CancleAdd buttonText={'Edit'} onClickCancle={OnClickCancle} disabled={isLoading} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
