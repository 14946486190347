import { useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate, useParams } from 'react-router-dom';
import InputLabel from '../../components/InputLabel/InputLabel';
import { useFieldArray, useForm } from 'react-hook-form';
import FillFormClientDefaultValue from './FillFormClientDefaultValue';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';
import { notifySuccess, onlyLetters } from '../../utils/utils';

import 'react-toastify/dist/ReactToastify.css';
import requestHttp from '../../http/requestHttp/requestHttp';
import CheckboxesFields from './components/CheckboxesFields';

export default function FillFormClient() {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const afterSubmit = () => {
    notifySuccess('Request successfully filled');
    setTimeout(() => {
      navigate('/Client/RequestTypes');
    }, 2000);
  };

  const onSubmit = data => {
    //   const values = filterStrings(data)
    const sendData = {
      formFields: data?.formFields.map(elem => {
        return {
          id: elem.id,
          value: elem?.type === 'checkbox' ? null : elem?.value,
          options: elem?.type === 'checkbox' ? elem?.options : null,
        };
      }),
    };
    setIsLoading(true);
    requestHttp.fillFormForClient(params.id, sendData)
      .then(() => afterSubmit())
      .catch(err => {
        err && setIsLoading(false);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );

      });
  };
  const OnClickCancle = () => {
    navigate('/Client/RequestTypes');
  };
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: useMemo(() => FillFormClientDefaultValue(form), [form]),
    // resolver: yupResolver(RequestErrorFillFormClient()),

  });

  const {
    fields: Fields,
    // append: ContractsAppend,
    // remove: ContractsRemove,
  } = useFieldArray({
    control,
    name: 'formFields',
  });

  useEffect(() => {
    reset(FillFormClientDefaultValue(form));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    requestHttp.getFormForFillClient(params?.id).then(e => {
      setForm(e?.data?.data);
    });
  }, []);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Fill Form for ${form?.title}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="py-4 w-full">
            {/* <div className="flex w-full gap-4"> */}
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
              {Fields?.map(({ label, type, id }, index) =>
                type === 'input' ? (
                  <div className="w-full" key={id}>
                    <InputLabel
                      labelTitle={label}
                      register={register}
                      name={`formFields.${index}.value`}
                      // onKeyPress={onlyLetters}
                      classNameLabel="text-[#60657B] opacity-50 text-xs"
                      classNameInput="flex flex-col flex-1"
                      type={'text'}
                      placeholder={label}
                      className="w-full"
                      required={{ required: 'This field is required' }}
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>}
                  </div>
                ) : (
                  type === 'date' && (
                    <div className="" key={id + label}>
                      <InputLabel
                        classNameInput="flex flex-col "
                        classNameLabel={'font-[system-ui] text-[#60657B] text-xs font-normal opacity-50'}
                        className="w-full border p-[7px]"
                        type="date"
                        labelTitle={label}
                        register={register}
                        name={`formFields.${index}.value`}
                        required={{ required: 'This field is required' }}
                      />
                      {errors && <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>}
                    </div>
                  )
                ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ name, label, type, id,}, index) =>
                  type === 'checkbox' && (
                    <div className="space-y-5 h-full" key={id + name + label}>
                      <p className="text-lg font-semibold">{label}</p>
                      <CheckboxesFields control={control} register={register} index={index} />
                    </div>
                  ),
              )}
            </div>
          </div>
          <div>
            <CancleAdd buttonText={'Fill'} onClickCancle={OnClickCancle} disabled={isLoading} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
