import { $authHost } from "..";

export default class EmployeeDashboardHttp {
  static employeeServicesLinks() {
    return $authHost.get(`/employee/my/services`);
  };
  static employeeAssignmentsLinks() {
    return $authHost.get(`/employee/my/assignments`);
  };
  static employeeCasesLinks() {
    return $authHost.get(`/employee/myCases`);
  };
  static employeeRequestsLinks() {
    return $authHost.get(`/employee/get_forms`);
  };
  static employeeRequestsWitoutPaginationLinks() {
    return $authHost.get(`/employee/getForms`);
  };
}
