

export const EditServiceLogCustomDefaultValue = (data) => {

    return {
        school_name : data?.school_name ,
        "Assignment/Case" : data?.["Assignment/Case"] ,
        
        start_date: data?.start_date,
        end_date: data?.end_date,
        // start_date: "2024-08-04",
        details : data?.details || [{}] 
    }
  
}

export default EditServiceLogCustomDefaultValue