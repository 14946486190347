import { toast } from 'react-toastify';
import { loadGetErrorDataSlice } from '../redux/errors/errors';

export default function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const notifySuccess = (message, err) =>
  toast(
  <p style={{ fontSize: 16 }}>{message}</p>, {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: false,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: false,
    type: err ? 'error' : 'success',
  });

export const onlyLetters = event => {
  if (!/^[a-zA-Z\s]+$/.test(event?.key)) {
    event?.preventDefault();
  }
};
export const onlyNumbers = event => {
  if (!/^[0-9]+$/.test(event.key)) {
    event.preventDefault();
  }
};

export function checkIntervalsOverlap(arr, dispatch) {
  for (let i = 0; i < arr.length; i++) {
    const currentInterval = arr[i];
    for (let j = i + 1; j < arr.length; j++) {
      const nextInterval = arr[j];
      if (
        (currentInterval.start >= nextInterval.start && currentInterval.start < nextInterval.end) ||
        ((currentInterval?.total < 480
          ? currentInterval.end > nextInterval.start
          : currentInterval.end >= nextInterval.start) &&
          currentInterval.end <= nextInterval.end) ||
        (nextInterval.start >= currentInterval.start &&
          (currentInterval?.total < 480
            ? nextInterval.start < currentInterval.end
            : nextInterval.start <= currentInterval.end)) ||
        (nextInterval.end > currentInterval.start && nextInterval.end <= currentInterval.end)
      ) {
        return dispatch(loadGetErrorDataSlice(true)); // Intervals overlap
      }
    }
  }
  return dispatch(loadGetErrorDataSlice(false)); // No intervals overlap
}


export function getCurrentMonthDates() {
  const now = new Date();
  
  // Get the start date of the month
  const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
  
  // Get the end date of the month
  const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Format the dates to 'MM/DD/YYYY'
  const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
  };
  
  return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
  };
}

export function formatStringForName(input) {
  return input.toLowerCase().replace(/\s+/g, "_");
}