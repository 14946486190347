import { Controller } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useEffect, useState } from 'react';

export default function DatePickerTime({ name, title, control, value, setError, setMinutDifError, index, setValue }) {
  const [dataPicker, setDataPicker] = useState(value);
  useEffect(() => {
    setDataPicker(value);
  }, [value]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }} //optional
      render={({
        field: { onChange, ...restField },
        fieldState: { invalid, isDirty }, //optional
        formState: { errors }, //optional, but necessary if you want to show an error message
      }) => (
        <div className="flex flex-col gap-2 w-full">
          <p className="text-[#60657B] opacity-50 text-xs">{title}</p>
          <DatePicker
            {...restField}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            disableDayPicker
            value={dataPicker}
            inputClass="px-2 py-2 border w-full rounded"
            format="hh:mm A"
            disabled={isOpen}
            editable={false}
            onChange={newValue => {
              const date = new Date();
              date.setMinutes(newValue?.minute);
              date.setHours(newValue?.hour);
              onChange(date);
              setDataPicker(date);
              // if ((newValue.minute !== 15) && (newValue.minute !== 0) && (newValue.minute !== 45) && (newValue.minute !== 30)) {
              //     setValue(`serviceLogTimeActivities.${index}.currentStepMinError`, true)
              //     setError(name, { type: "custom", message: "Minut Step must be 1 min" })
              // } else {
              //     setValue(`serviceLogTimeActivities.${index}.currentStepMinError`, false)
              //     setError(name, { type: "custom", message: "" })
              // }
            }}
            plugins={[<TimePicker hideSeconds mStep={15} />]}
          />
        </div>
      )}
    />
  );
}
